.project-table>thead>tr>th {
  background-color: #CED2E0;
}

.project-table.phoenix-table>thead>tr>th:first-child,
.project-table.phoenix-table>tbody>tr>td:first-child {
  padding-left: 15px !important;
  /* Adjust the padding as needed */

}

.project-table.phoenix-table>thead>tr>th:last-child,
.project-table.phoenix-table>tbody>tr>td:last-child {
  padding-right: 15px !important;
  /* Adjust the padding as needed */
}

.filter_select {
  width: 147px;
}

.lableSelect label {

  font-weight: 500;
  cursor: pointer;
}

.page-link.active,
.active>.page-link {
  background-color: #CBD0DD !important;
  color: #31374A !important;
}

.page-link:hover {

  background-color: #0A1E63;
  color: #fff;
}

.project_list-btn {

  background-color: transparent !important;
  /* border-radius: 0.375rem; */
}

.rounded-1 {

  border-radius: 0.375rem !important;
}

.project_list-btn svg path {

  fill: #6E7891 !important;
}

.project_list-btn:hover {

  background-color: transparent !important;
}

.overflow-visible.card .table-responsive {

  border-radius: 0.375rem !important;
}

.project-table tr {

  border-bottom: 1px #eeeff0;
}

.project-table tbody tr:last-child {

  border-bottom: 1px solid rgb(203 208 221);
}

.navbar-nav.custom_admin.navbar-nav-icons.flex-row .dropdown .dropdown .dropdown-menu {
  left: -201px !important;
  top: -5px !important;
}

.custom_admin.navbar-nav.navbar-nav-icons.flex-row .navbar-collapse.collapse {

  display: flex !important;
}



.custom_adminInner .navbar-dropdown-caret {

  left: -50px !important;
}

.custom_adminInner .nav-link.lh-1>svg {

  display: none !important;
}

.avatar.avatar-s span {

  font-size: 11px !important;
}

.loggedin-user>.avatar-name {

  background: #2A47AB33 !important;
}

.normal-user>.avatar-name {
  background: #ffe0db !important;
}

.menu-project-logo>.avatar-name {
  background: #c7ebff !important;

  border: 1px solid #96d9ff !important;
}

.custom-card {
  background-color: #F9F9F9 !important;
  border: none !important;
}

.timeline-size {
  max-height: 300px;
  overflow-y: scroll;
}

/* .navbar-horizontal .navbar-nav-top .nav-item.dropdown.active{
    border-bottom: 2px solid #007bff; 
  
  }
  .navbar-horizontal .navbar-nav-top .nav-item.dropdown.active{
  
    border-bottom: 2px solid #007bff; 
  } */

.disabled_link {
  pointer-events: none;
  text-decoration: none;
}

td a.text-black {

  color: #31374A !important;
}


.section_formm {
  font-size: 0.9rem !important;
}

/* .project_padding.table.phoenix-table > tbody > tr > td {
    vertical-align: middle !important;
    padding: 11px 0px;
  } */
.project-table a.text-decoration-none.fw-semi-bold {

  max-height: 1.4em;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.hover-actions-trigger.card .text-decoration-none.fw-bold.fs-8 {
  max-height: 1.4em;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

}

/* .shadow-none.border-300.overflow-visible.card a.text-decoration-none.fw-bold.text-black{
  
    max-height: 1.4em;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  } */

.navbar.navbar-slim .dropdown-menu.navbar-dropdown-caret:after {

  left: 24%;
}

.nav-underline .under_line .nav-link.active {

  border-bottom: 3px solid #ccd0dd !important;
}

.font_size .badge-phoenix {
  text-transform: capitalize;

}

.custom-file-button input[type=file] {
  margin-left: -2px !important;
}

.custom-file-button input[type=file]::-webkit-file-upload-button {
  display: none;
}

.custom-file-button input[type=file]::file-selector-button {
  display: none;
}

.custom-file-button:hover label {
  background-color: #dde0e3;
  cursor: pointer;

}

.was-validated .form-control.is-invalid:valid {

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ed2000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ed2000' stroke='none'/%3e%3c/svg%3e") !important;
  border-color: #ed2000;
}

.avatar_width .avatar .avatar-name>span {

  font-weight: 700 !important;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #2A47AB !important;
}

.action-point-title {
  color: #3874ff;
  cursor: pointer;
}

.workspace-btn-title:hover{
  color: #3874ff;
  cursor: pointer;
  text-decoration: underline;
}
.user-profile-name:hover{
  color: #3874ff !important;
  cursor: pointer;
}
.profile-link{
  cursor: pointer;
}
.avatar_width {

  width: 40px;
}

.text-capitlize {

  text-transform: capitalize !important;
  color: #141824ab !important;
}

.text-capitlize .badge-phoenix-secondary {

  color: #141824ab !important;
}

.wbsdesign {

  color: #6E7891;
  font-size: 15px;
  font-weight: 800;
}

.project_list-btn.btn-wdbs {
  padding: 2px 5px 0px 5px !important;
}

.btn-wdbs:hover .wbsdesign {

  color: #fff;
}

.border_design {

  border-right: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.toggle_border svg {

  border: 1px solid #CBD0DD;
  width: 40px;
  border-radius: 4px;
  cursor: pointer;
}

.nav-item-wrapper .nav-item .nav-link.label-1.active {

  background-color: #0A1E63;
  color: #fff;

}

.nav-item-wrapper .nav-item .nav-link.label-1.active svg {
  color: #fff;
}

.nav-item-wrapper .nav-item .nav-link.label-1.active .nav-link-text {

  color: #fff;
}

.nav-item.active {
  background-color: #CBD0DD;
  /* Your desired background color */
  border-radius: 2px;

}

.nav-item.active a {

  font-weight: 700;
}

.title_overview {

  color: #71717A;
}


.custom_adminInner .nav-link.lh-1.dropdown-toggle::after {

  display: block !important;
}

.form-control.is-invalid~.eye-icon svg {
  right: 33px !important;
  top: 25px !important;

}

.form-control.is-invalid~.eye-icon2 svg {
  right: 33px !important;
  top: 19px !important;

}

.was-validated .form-control:valid~.eye-icon svg {
  right: 33px !important;
  top: 25px !important;

}

.was-validated .form-control:valid~.eye-icon2 svg {
  right: 33px !important;
  top: 19px !important;

}

.border-bottom-2 {

  border-bottom: 1px solid #C4C4C4
}

.colorSet_heading {

  color: #0A1E63;
}

.projectInfo_card {

  background-color: #0A1E630D !important;
  padding: 20px;
}

.bg-customs {
  background-color: #0A1E630D !important;
}

.text-overflow-ellips {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dots_red {

  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #BA1717;
  display: inline-flex;
  margin-right: 4px;

}

.navbar-horizontal .navbar-nav-top .dropdown-item.active {

  background-color: #0A1E63 !important;
  color: #fff !important;
}

.actionPoint .flatpickr-input {

  padding: 0.8rem 2rem !important;
  width: 170px !important;
}

.navbar_actions {

  background: #0A1E630D;
  padding: 0px 10px 0px 10px;
  border-radius: 4px;

}

.border-rightSet {

  border-right: 1px solid #31374A;
  padding-right: 35px;

}

/* .navbar_actions a{

    color: #525B75;
  } */

.mobileSlide {
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 10px;
  padding-top: 10px;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: rgb(241, 228, 228);
}

::-webkit-scrollbar-thumb:horizontal {
  background: #7b7979;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:vertical {
  background: #7b7979;
  border-radius: 10px;
}

.archive-row {
  filter: alpha(opacity=60);
  /* IE */
  -moz-opacity: 0.6;
  /* Mozilla */
  opacity: 0.6;
  filter: alpha(opacity=60);
}

.border-size-2 {
  border: 2px solid #2A47AB;
}

.border-size-1 {
  border: 2px solid #2A47AB;
}

.project-icon-remove {
  background-color: #FC959A !important;
  color: #ffffff;
}

.timline-update {
  border-color: green !important;
}

.actionpoint-filters {
  cursor: pointer;
  color: #3874ff !important;
}

.selected-actionpoint-filters {
  border-bottom: 2px solid #0A1E63;
}

.accordion-item.accordion_bg {

  min-height: 109px;
  border: 1px solid #E3E6ED;
  position: relative !important;
  border-radius: 0.5rem !important;
}


.accordion_bg .accordion-header button {

  line-height: 10px;
}

.accordion_bg .accordion-header .accordion-button::after {

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 24 24' fill='%23525B75' class='text-900 fs-5'%3E%3Cpath d='M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z'%3E%3C/path%3E%3C/svg%3E") !important;
}

.accordion_bg .accordion-body {

  padding-bottom: 30px;

}

.showText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer_accordion {
  background: #fff;
  padding: 10px 15px 10px 15px;
  border-radius: 0 0.5rem 0.5rem;
  position: absolute;
  width: 97%;
  bottom: 1px;
  left: 1px;
}

.timeline-custom-update {
  border: 1px solid #09c7cd !important;
}

.timeline-custom-create {
  border: 1px solid #525B75 !important
}

.timeline-custom-reopen {}

.timeline-custom-completed {
  border: 1px solid #8FC644 !important
}

.timeline-custom-pending {}

.timeline-custom-approved {
  border: 1px solid #2e4b07 !important
}


.text-primary-update {
  color: #09c7cd;
}

.text-primary-create {
  color: #525B75;
}

.text-primary-reopen {
  color: #8FC644;
}

.text-primary-completed {
  color: #8FC644;
}

.text-primary-pending {}

.text-primary-approved {
  color: #2e4b07;
}

.timeline-details-update {
  color: #09c7cd;
}

.timeline-details-create {
  color: #525B75;
}

.timeline-details-reopen {
  color: #8FC644;
}

.timeline-details-completed {
  color: #8FC644;
}

.timeline-details-pending {}

.timeline-details-approved {
  color: #2e4b07;
}

.text-time-update {
  color: #09c7cd;
}

.text-time-create {
  color: #525B75;
}

.text-time-reopen {
  color: #8FC644;
}

.text-time-completed {
  color: #8FC644;
}

.text-time-pending {}

.text-time-approved {
  color: #2e4b07;
}

.version-type {
  color: #fffb06;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  background-color: #df0c4f;
  padding: 2px 10px 2px 10px;
  border-radius: 15px;
  font-family:"Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.customDate {
  top:35% !important
}
.active-workspace{
  background-color: #CBD0DD;

}
.active-workspace>a>span{
  color: #0a1e63 !important;
}
.current-active-workspace{
  color: #3874ff;
}

.current-workspace:hover{
  color: #3874ff;
  cursor: pointer;
}

.custom-date-filter{
  min-width: 220px;
}
.accordian-header:hover {
  cursor: pointer;
}
/* media queiry */

@media(min-width:991px) {

  .navbar-vertical.navbar-expand-lg .navbar-vertical-content.custom_navbar-design .navbar-nav .nav-link {

    padding-left: 0.5rem !important;
  }

  .navbar-vertical.navbar-expand-lg .navbar-vertical-content.custom_navbar-design .navbar-nav .nav-link {
    margin-bottom: 8px;

  }

  .navbar-vertical.navbar-expand-lg .navbar-vertical-content.custom_navbar-design .navbar-nav .nav-link:hover {

    background-color: #0A1E63;
  }

  .navbar-vertical .navbar-vertical-content.custom_navbar-design .navbar-nav .nav-link:hover svg {

    color: #fff;
  }

  .navbar-vertical-collapsed .navbar-vertical .navbar-vertical-content.custom_navbar-design .navbar-nav .nav-link:hover span {

    color: #525b75;
  }

  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-vertical-content.custom_navbar-design .navbar-nav .nav-link.label-1 {

    padding-left: 0.75rem !important;
  }

  .navbar-vertical .navbar-vertical-content .navbar-nav .nav-link:hover {

    color: #fff !important;
  }

  .custom_selectWidth .form-floating #lead-woner {

    width: 160px !important;
  }

}

@media(max-width:991px) {

  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown .dropdown-menu {
    left: -178px;
    top: -3px;
    background: #ffff;
    padding: 4px;
  }
}

@media (max-width: 992px) {

  /* .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg:hover {

      width: 15.875rem;
      border-right: 1px solid var(--phoenix-navbar-vertical-border-color);
      margin: 0;
      transition: width 0.2s ease;
  } */
  .mobile_devicesIcon button svg {

    width: 16px;
    height: 16px;

  }

  .mobile_devicesIcon button h4 {

    font-size: 14px;
  }

  .custom_adminInner .nav-link.lh-1 {

    height: 28px;
  }

}

@media(min-width:768px) {
  .custom_adminInner>.nav-item.dropdown a.nav-link {

    background-color: #F5F7FA;
  }

  .mobileSlide {
    overflow-x: auto;
    flex-wrap: nowrap;
  }


  .mobileSlide>div {
    flex: 0 0 auto;
    /* Prevent flex items from wrapping */
  }

}

@media(max-width:576px) {

  .filter_select {

    width: 100%;
    margin-bottom: 5px;
  }

  a.font_sizeset {
    font-size: 16px !important;
  }
}